app.service('rodo',function(loading, modal, validate, html, front, captchaService){
	var $form, $select, $phone, $pesel, $email;

	function validateForm(){
		validate.task('rodoForm', function(){
			submit();
		});
	}
	function send(){
		$form.addClass('loading');
		$form.ajaxSubmit({
			url:'/frontUserContactForm.ltr',
			data:{
				type: $select.val()
			},
			type: 'POST',
			success:function(result){
				if (!$('<div>'+result+'</div>').find('#rodo-captcha .error-message').length){
					$.scrollTo($('#rodo').offset().top-300,200);
				}
				if($('<div>'+result+'</div>').find('.row').length || !front.katalog){
					$form.html(result);
					initForm();

				}else{
					$form.find('.send-success').remove();
					$form.before(result);
					if(front.katalog){
						html.add('print-mode');
					}
				}
			},
			complete:function(){
				$form.removeClass('loading');
			},error:function(){
				modal.alert('Błąd wysyłania formularza. Spróbuj ponownie później');
			}
		});
	}
	function submit(){
		if($form.valid()){
			if(($phone.length && $phone.val()!='') || ($email.length && $email.val()!='')){
				send();
			}else{
				modal.generate({
					id:'rodo-message',
					content:'Podane dane mogą być niewystarczające do identyfikacji uzupełnij nr telefonu lub e-mail. <br/>Czy chcesz uzupełnić formularz?',
					buttons:[{
						text:'Nie, wyślij mimo to',
						cssClass:'send',
						click:function(){
							send();
							modal.close();
						}
					},{
						text:'Tak, popraw dane',
						cssClass:'back',
						click:function(){
							modal.close();
						}
					}]
				});
			}
		}
	}

	function changePage(value){
		var url="/cms/";
		switch(value) {
			case 'remove':
				url+='pomoc-rodo-usuniecie.bhtml';
				if(front.ole){
					url+='?sp=e0108';
				}
				break;
			case 'restriction':
				url+='pomoc-rodo-inne.bhtml';
				if(front.ole){
					url+='?sp=e0107';
				}
				break;
			case 'correct':
				url+='pomoc-rodo-sprostowanie.bhtml';
				if(front.ole){
					url+='?sp=e0106';
				}
				break;
			case 'inform':
				url+='pomoc-rodo-informowanie.bhtml';
				if(front.ole){
					url+='?sp=e0105';
				}
				break;
			case 'objection':
				url+='pomoc-rodo-sprzeciw.bhtml';
				if(front.ole){
					url+='?sp=e0109';
				}
				break;
			default:
				break;
		}
		loading.redirect(url);
	}
	function initForm(){
		$phone=$("#rodo-phone");
		$email=$("#rodo-email");
		$pesel=$("#rodo-pesel");
		$form.removeClass('loading');
		$phone.keyfilter(/[\d\- ]/);
		$pesel.keyfilter(/[\d]/);
		validateForm();
		addRodoCaptcha();
	}
	function addRodoCaptcha() {
		var $captcha = $form.find('[data-toggle="captcha"]');
		if ($captcha.length) {
			captchaService.load($captcha.attr('id'), $captcha.data('place'));
		}
	}
	function loadForm(){
		loading.start();
		$.ajax({
			url:'/frontUserContactForm.ltr',
			data:{
				type: $form.data('type')
			},
			success:function(html){
				$form.html(html);
				initForm();
			},
			complete:function(){
				loading.stop();
			}
		});
	}
	function setElements() {
		$form=$('#rodo');
		$select=$('#rodo-select');
	}
	function setEvents() {
		$select.change(function(){
			changePage($(this).val());
		});
		loadForm();
	}
	function init(){
		setElements();
		setEvents();
	}
	return {
		init:init
	};
});
