app.service('cms', function (rodo, autocompleteInfo){
	$(document).on('pageChange', function() {
		run();
	});
	function setElements() {

	}
	function run(){
		if($('form#rodo').length){
			rodo.init();
		}
	}
	function setEvents() {
		run();
	}
	function init(){
		setElements();
		setEvents();
		autocompleteInfo.logResult('cms');
	}
	$(document).ready(function(){
		init();
	});
});
